import Vue from 'vue';

Vue.filter('capitalize', function (value) {
  if (!value) {return '';}
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('randomBool', function (value) {
  return (function() {
    let t = value += + 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  })() >= 0.5; //adjust 0.5 if product wants more trues or more falses
});
