import { persistedStateOptions } from '../store';

export const localStorageMixin = {
  data() {
    return {
      instanceId: String(Math.floor(Math.random() * 100) * Date.now()),
      instanceKeyName: `cendyn-${ window.location.hostname }-instance`,
      dataOwnerInstanceKeyNamePrefix: `cendyn-${ window.location.hostname }-data`,
      $vuexStorage: {}
    };
  },
  async created() {
    window.addEventListener('storage', async (storageEvent) => {
      // someone requests a new copy of vuex from the current instance
      if (storageEvent.key === this.instanceKeyName && storageEvent.newValue !== this.instanceId) {
        // The new value is the instanceid of the new tab/window so we save it for that instance
        // Data is set for specific id because if user opens many tabs within seconds,
        // the first tab would remove the item from the localStorage while the other tabs/windows try to get it...
        window.localStorage.setItem(`${ this.dataOwnerInstanceKeyNamePrefix }-${ storageEvent.newValue }`, persistedStateOptions.storage.getItem(persistedStateOptions.key));
      }

      // this is for the requesting instance which has been opened in the new tab
      if (storageEvent.key === `${ this.dataOwnerInstanceKeyNamePrefix }-${ this.instanceId }`) {
        persistedStateOptions.storage.setItem(persistedStateOptions.key, storageEvent.newValue);
        window.localStorage.removeItem(`${ this.dataOwnerInstanceKeyNamePrefix }-${ this.instanceId }`);
        this.$vuexStorage = JSON.parse(storageEvent.newValue);
      }

    });

    window.localStorage.setItem(this.instanceKeyName, this.instanceId);
  }
};
