import Vue from 'vue';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaVue from '@okta/okta-vue';
import { EventBus } from '@cendyn/cendyn-frontend-kit';
import router from '../router';

export const createOktaAuth = (config, execVerifyUser = true) => {

  const { VUE_APP_CLIENT_ID, VUE_APP_ISSUER, VUE_APP_DISABLEHTTPSCHECK, VUE_APP_LOGOUT_URL } = config;

  const oktaAuth = new OktaAuth({
    clientId: VUE_APP_CLIENT_ID,
    issuer: VUE_APP_ISSUER,
    redirectUri: window.location.origin + '/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: VUE_APP_DISABLEHTTPSCHECK
    },
    postLogoutRedirectUri: VUE_APP_LOGOUT_URL
  });

  Vue.use(OktaVue, { oktaAuth });

  oktaAuth.verifyUser = async () => {

    EventBus.$emit('event-load-start');

    let session = await oktaAuth.session.exists();
    let authentic = await oktaAuth.isAuthenticated();

    if (!(authentic)) {
      //User not authenticated send to Sign In
      return false;
    }

    if (!(session) && authentic) {
      //User has no active session but authenticated send to Sign In
      return 'invalid';
    }

    if (authentic && session) {
      const token = await oktaAuth.getAccessToken();
      const user = await oktaAuth.getUser();

      //If the User Does not have a Cendyn ID then route them to Error page
      if (user.cendynId === undefined) {
        router.push({name: 'Error'});
      }
      EventBus.$emit('event-load-complete');
      return { user, token };
    }

  };

  execVerifyUser && oktaAuth.verifyUser();

  return oktaAuth;

};