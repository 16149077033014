/* eslint-disable no-useless-escape */
import axios from 'axios';

export const createActions = (config) => {

  const apiOkta = config.VUE_APP_ISSUER.split('/oauth2')[0];

  const okta = `${apiOkta}/api/v1/authn/recovery/password`;

  const actions = {
    passwordReset ({ commit }, {user}) {
      commit('okta/SET_LOADING', true);
      return new Promise((resolve, reject) => {
        axios.post(okta, {
          'username': user,
          'factorType': 'EMAIL',
          'relayState': 'https://gocendyn.com'
        })
          .then(response => {
            resolve(response);
          }, error => {
            reject(error);
          });
      });
    }
  };

  return actions;
};