import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

// const urlParams = new URLSearchParams(window.location.search)

// to handle state
const state = {
  alertshow: false,
  alert: {}
};

export const alerts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
