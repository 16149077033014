<template>
  <div id="okta-signin-container"></div>
</template>

<script>
import OktaSignIn from '@okta/okta-signin-widget';
import '@/assets/okta/sass/okta-sign-in.scss';
import { mapActions } from 'vuex';
import { signinOkta } from '../okta/oktaConfig';

export default {
  name: 'Login',
  mounted: function () {
    const okta = signinOkta(this.$config);
    this.$nextTick(function () {
      this.widget = new OktaSignIn(okta);
      this.widget.showSignInToGetTokens({
        el: '#okta-signin-container',
        scopes: ['openid', 'profile', 'email']
      }).then(tokens => {
        this.fetchApplicationsAsync().then((res)=> {
          const app = res.filter(x => x.name === 'Home');
          const appId = app[0]?.applicationId;
          this.setCurrentUserStore({ TOKEN: tokens.accessToken.accessToken, INFO: tokens.idToken.claims, APPID: appId, ORGID: null });
        });
        this.$nextTick(function () {
          this.$auth.handleLoginRedirect(tokens);
        });
      }).catch(err => {
        throw err;
      });
    });
  },
  destroyed () {
    this.widget.remove(); // Remove the widget from the DOM on path change
  },
  methods: {
    ...mapActions('currentUser', [
      'setCurrentUserStore'
    ]),
    ...mapActions('cendynRegistry', [
      'fetchApplicationsAsync'
    ])
  }
};
</script>
