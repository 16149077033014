import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import '@/polyfills';
import '../src/filters/index';
import Alert from '@cendyn/cendyn-frontend-kit';
import { createOktaAuth } from './okta/createOktaAuth';
import { createStore } from './store';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

require('../node_modules/jquery/dist/jquery');
require('../node_modules/bootstrap/dist/js/bootstrap.bundle.min');

Vue.use(Alert);

Vue.config.productionTip = false;

//Retrieve Variables for App
fetch('env.config.json')
  .then(response => response.json())
  .catch(() => process.env)
  .then(config => {
    console.info('CENDYN APP START - APP CONFIGURATION', config);
    const appInsights = new ApplicationInsights({ config: {
      instrumentationKey: config.VUE_APP_INSTRUMENTATIONKEY,
      connectionString: config.VUE_APP_INGESTIONENDPOINT
    }});
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    Vue.prototype.$config = config;
    const store = createStore(config);
    const oktaAuth = createOktaAuth(config, false);
    const app = new Vue({
      store,
      router,
      axios,
      oktaAuth,
      render: h => h(App)
    });
    app.$mount('#app');
  });
