<template>
  <div class="nonauth-layout container-fluid">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LoginLayout'
};
</script>

<style lang="scss">
  .nonauth-layout {
    background-color: #ffffff;
    height: 100vh;
    width: 100vw;
  }
</style>