import Vue from 'vue';
import VueRouter from 'vue-router';
import Signin from '@/views/Signin.vue';
import LoginLayout from '@/layouts/LoginLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import { PostNavigationGuard } from '@cendyn/cendyn-frontend-kit';
import { APPLICATION_PERMISSION_MAP } from '../constants';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Signin',
    meta: { layout: LoginLayout, title: 'Sign In'  },
    component: Signin
  },
  {
    path: '/login/callback'
  },
  {
    path: '/home',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: {
      layout: MainLayout,
      title: 'Home',
      requiresAuth: true,
      requiresPermissions: [APPLICATION_PERMISSION_MAP.CHCHomeStandardUser]
    }
  },
  {
    path: '/user',
    component: () => import(/* webpackChunkName: "user" */ '@/views/User.vue'),
    meta: {
      layout: MainLayout,
      title: 'User Account',
      requiresAuth: true,
      requiresPermissions: [APPLICATION_PERMISSION_MAP.CHCHomeStandardUser]
    },
    children: [
      {
        path: '',
        name: 'User',
        component: () => import(/* webpackChunkName: "general" */ '@/views/user/General.vue'),
        meta: { layout: MainLayout, title: 'General' }
      },
      {
        path: 'security',
        name: 'Security',
        component: () => import(/* webpackChunkName: "security" */ '@/views/user/Security.vue'),
        meta: { layout: MainLayout, title: 'Security' }
      },
      {
        path: 'preferences',
        name: 'Preferences',
        component: () => import(/* webpackChunkName: "preferences" */ '@/views/user/Preferences.vue'),
        meta: { layout: MainLayout, title: 'Preferences' }
      }
    ]
  },
  {
    path: '/error',
    name: 'Error',
    component: () =>
      import(/* webpackChunkName: "notfound" */ '@/views/Error.vue'),
    meta: { title: 'Error', type: 'noCID' }
  },
  {
    path: '/noauth',
    name: 'NotAuthorized',
    component: () =>
      import(/* webpackChunkName: "notfound" */ '@/views/Error.vue'),
    meta: { title: 'Error', type: 'noauth' }
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "notfound" */ '@/views/Error.vue'),
    meta: { title: 'Error', type: 'notfound' }
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { title: 'Logout' },
    component: () =>
      import(/* webpackChunkName: "logout" */ '../views/Logout.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active'
});

const onAuthRequired = async (from, to, next) => {
  if (from.matched.some(record => record.meta.requiresAuth) && !(await Vue.prototype.$auth.isAuthenticated())) {
    // User Not Authenticated Redirect to Login
    next({ path: '/login' });
  } else {
    // User Meets Requirements
    next();
  }
};

router.beforeEach(onAuthRequired);

router.beforeEach((to, from, next) => {
  document.title = 'CHC - ' + to.meta.title || 'Cendyn';
  next();
});
router.afterEach(PostNavigationGuard({store: undefined}));

export default router;
