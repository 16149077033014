import { createActions } from './actions';

// to handle state
const state = {
  loading: false
};

export const createOktaStore = (config) => {
  const okta = {
    namespaced: true,
    actions: createActions(config),
    state
  };

  return okta;
};
