import {CommonStoreState} from '@cendyn/cendyn-frontend-kit';

export const state = {
  criticalErrors: CommonStoreState.criticalErrors,
  normalErrors: [
    ...CommonStoreState.normalErrors,
    ...[
      'countries',
      'countryCurrencies',
      'dateFormats',
      'languages',
      'timeZones',
      'properties',
      'users'
    ]
  ]
};