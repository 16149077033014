<template>
  <div class="auth-layout">
    <div v-if="pageErrors.length">
      <PageError
        :data="pageErrorData"
        :image-url="require('../assets/img/errors/bg-image.png')"
      >
        <template #action>
          <p>
            <Button color="primary" @clicked="$router.go(-1)">Go Back</Button>
          </p>
          <p>
            <router-link to="/">Contact Support</router-link>
          </p>
        </template>
      </PageError>
    </div>
    <div v-else>
      <!-- The Nav Swap Button -->
      <a id="swapNav" href="#" :class="dynamicNav" @click.prevent="switchNav()">
        <base-icon icon-name="menu-min" size="16" viewBox="0 0 16 16"></base-icon>
      </a>

      <!-- The Navigation -->
      <component
        :is="dynamicNav"
        :application-name="'Home'"
        :application-icon="'app-cloud'"
        :applications="getApplications"
        :loading-apps="getLoadingStatus.applications"
      >
      </component>

      <div class="main-content">
        <!-- The Topnav -->
        <TheTopnav
          :organization="'Show'"
          :org-type="'Do Not'"
          :org-data="[]"
          :search="true"
          :notification="false"
          :locale="false"
          :locale-icon="'settings'"
          :locale-links="[]"
          :user="true"
          :user-img="''"
          :user-alt="''"
          :user-initials="getUserInitials"
          :user-links="[]"
          :user-logout="'Logout'"
          :home-link="homeLink"
          @logout.prevent="logout()"
        >
          <template #searchform>
            <a :href="help.link" target="_blank" class="dropdown-item">
              {{ help.label }}
            </a>
          </template>
        </TheTopnav>

        <!-- The Content Area -->
        <div class="container-fluid">
          <div v-if="stateErrors.length" class="d-flex align-items-center vh-100">
            <PageMessage
              :title="stateErrorData.title"
              :description="stateErrorData.message"
              :img-url="require('@cendyn/cendyn-frontend-kit/src/assets/icons/illustrations/database.svg')"
            ></PageMessage>
          </div>
          <div v-else>
            <slot />
          </div>
          <!-- <slot /> -->
        </div>
      </div>

      <!-- Application Launcher -->
      <AppLauncher
        :application-name="getUserCurrentApp.applicationName"
        :application-search="true"
        :application-search-placeholder="'Search'"
        :applications="getApplications"
        :loading-apps="getLoadingStatus.applications"
        :user-applications="userApps"
        @select-application="onSelectApplication"
      />

      <!-- The Organization Selector -->
      <organization-selector ref="orgSelector" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import {
  BaseIcon,
  Button,
  Modal,
  TheNav,
  TheNavSmall,
  TheTopnav,
  AppLauncher,
  OrganizationSelector,
  PageMessage,
  PageError
} from '@cendyn/cendyn-frontend-kit';

export default {
  name: 'MainLayout',
  components: {
    BaseIcon,
    Button,
    Modal,
    TheNav,
    TheNavSmall,
    TheTopnav,
    AppLauncher,
    OrganizationSelector,
    PageMessage,
    PageError
  },
  data() {
    return {
      dynamicNav: 'the-nav',
      help: {
        label: 'Help',
        link: 'https://help.cendyn.com/'
      }
    };
  },
  computed: {
    ...mapGetters(['pageErrors', 'stateErrors']),
    ...mapGetters('currentUser', [
      'getUserInfo',
      'getUserApps',
      'getUserCurrentApp',
      'getUserInitials',
      'getShowOrgSelectorData'
    ]),
    ...mapGetters('cendynRegistry', [
      'getApplications',
      'getLoadingStatus'
    ]),
    userApps() {
      let arr = this.getUserApps.map(x => x.applicationId);
      return arr;
    },
    homeLink() {
      return '';
    },
    loadingApps() {
      return this.getLoadingStatus.applications;
    },
    pageErrorData() {
      const errorCode = this.pageErrors[0].error?.response?.status;
      return {
        preheader: `${errorCode} ERROR`,
        header: this.pageErrors[0].title,
        message: this.pageErrors[0].message
      };
    },
    stateErrorData() {
      const errorCode = this.stateErrors[0].error?.response?.status;
      return {
        preheader: `${errorCode} ERROR`,
        title: this.stateErrors[0].title,
        message: this.stateErrors[0].message
      };
    }
  },
  methods: {
    ...mapActions('currentUser', [
      'fetchUserPrefAsync',
      'fetchUserAppsAsync',
      'fetchUserRolesAsync',
      'fetchUserOrgsAsync',
      'setUserCurrentOrg'
    ]),
    switchNav() {
      const display = this.dynamicNav === 'the-nav-small' ? 'the-nav' : 'the-nav-small';
      this.dynamicNav = display;
    },
    async onSelectApplication(application) {
      let orgSelectorData = this.getShowOrgSelectorData(application.name);
      let organizations = undefined;

      if (orgSelectorData.showOrganizationSelector) {
        organizations = await this.fetchUserOrgsAsync({
          appId: application.applicationId,
          saveToStore: false
        });
      } else {
        organizations = [{ accountId: orgSelectorData.organizationId }];
      }

      this.$refs.orgSelector.selectApplication({
        application,
        organizationIds: organizations.map(o => o.accountId),
        currentOrganization: this.currentOrganization,
        callback: this.fetchUserOrgsAsync({
          appId: application.applicationId,
          saveToStore: false
        })
      });
    },
    logout() {
      this.$router.push({ name: 'Logout' });
    }
  }
};
</script>
