
<template>
  <div class="login row flex-row align-items-stretch">
    <div class="col-4">
        <Login />
    </div>
    <div class="col-8">
      <div class="bg-cover">
        <img id="chc_logo" src="@/assets/img/login/chc-logo.svg" class="img-fluid" alt="Cendyn Hospitality Cloud logo" />
      </div>
    </div>
  </div>
</template>

<script>
import Login from '../components/Login.vue';

export default {
  name: 'Signin',
  components: {
    Login
  },
  data: function() {
    return {
      header: 'Cendyn Hospitality Cloud',
      message: 'Sign in'
    };
  }
};
</script>

<style lang="scss">
  @import "@cendyn/cendyn-frontend-kit/src/assets/scss/_app-variables.scss";
  .login {
    height: 100%;
  }
  #okta-signin-container {
    height: 100%;
  }
  #chc_logo {
    margin-right: 50px;
  }
  .bg-cover {
    text-align: right;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url("../assets/img/login/chc-graphic.svg");
    background-position: center right !important;
    background-size: 90% !important;
  }
</style>
