import Vue from 'vue';
import Vuex from 'vuex';
import { alerts } from './Alerts';
import { createOktaStore } from './Okta';
import { createCurrentUserModule, createRegistryModule, StoreHelper, CommonStoreActions, CommonStoreGetters } from '@cendyn/cendyn-frontend-kit';
import createPersistedState from 'vuex-persistedstate';
import { state } from './state';
export const persistedStateOptions = {
  key: 'vuex',
  paths: ['currentUser', 'cendynRegistry'],
  storage: window.sessionStorage,
  reducer: StoreHelper.getPersistedStateReducer(['currentUser/userLoading'])
};

export const createStore = (config) => {

  Vue.use(Vuex);

  const store = new Vuex.Store({
    state,
    modules: {
      alerts,
      okta: createOktaStore(config),
      currentUser: createCurrentUserModule(config),
      cendynRegistry: createRegistryModule(config)
    },
    plugins: [createPersistedState(persistedStateOptions)],
    getters: CommonStoreGetters,
    actions: CommonStoreActions
  });
  return store;
};