
export const signinOkta = (config) => {

  const { VUE_APP_CLIENT_ID, VUE_APP_ISSUER, VUE_APP_LOGOUT_URL } = config;

  const loginUrl = new URLSearchParams(window.location.search);

  const recoveryToken = loginUrl.get('token');

  const oktaconfig = {
    baseUrl: VUE_APP_ISSUER.split('/oauth2')[0],
    clientId: VUE_APP_CLIENT_ID,
    redirectUri: window.location.origin + '/callback',
    postLogoutRedirectUri: VUE_APP_LOGOUT_URL,
    logo: '',
    i18n: {
      en: {
        'primaryauth.title': 'Sign In',
        'primaryauth.message': 'Message',
        'primaryauth.username.placeholder': 'Email Address',
        'primaryauth.username.tooltip': 'Enter your email address',
        'primaryauth.password.placeholder': 'Password',
        'primaryauth.password.tooltip': 'Super secret password',
        // Errors
        'error.username.required': 'Required',
        'error.password.required': 'Required',
        'errors.E0000004': 'Sign in failed!',
        // Pasword Reset
        'password.reset': 'Reset Password',
        'password.forgot.email.or.username.placeholder': 'Email address',
        'password.forgot.email.or.username.tooltip': 'Email address',
        'password.forgot.emailSent.desc': 'If an account exists for {0} you will receive an email with instructions on unlocking your account.',
        //Unlock Account
        'account.unlock.emailSent.desc': 'If an account exists for {0} you will receive an email with instructions on unlocking your account.',
        // Common
        'recovery.sms.hint': 'Enter your email address or username.',
        //MFA Enroll
        'enroll.choices.setup': 'Setup',
        'enroll.choices.title': 'Multifactor Authentication',
        'enroll.choices.description.generic': 'With multifactor authentication to add an additional layer of security when signing in to your Cendyn Hospitality Cloud account.',
        'mfa.sendCode': 'Send Code',
        //MFA SMS
        'enroll.sms.setup': 'SMS Authentication',
        'enroll.sms.description': 'Testing Description',
        //MFA Email
        'email.enroll.title': 'Email Authentication',
        'email.button.resend': 'Resend',
        'email.mfa.title': 'Email Authentication'
      }
    },
    features: {
      rememberMe: false,
      selfServiceUnlock: true,
      smsRecovery: false,
      showPasswordToggleOnSignInPage: true,
      registration: false,
      multiOptionalFactorEnroll: true
    },
    helpLinks: {
      help: 'https://help.cendyn.com'
    },
    authParams: {
      issuer: VUE_APP_ISSUER,
      scopes: ['openid', 'profile', 'email'],
      pkce: true
    }
  };

  if (recoveryToken){
    oktaconfig.recoveryToken = recoveryToken;
  }

  return oktaconfig;
  
};
