<template>
  <div id="app">
    <full-page-loader v-if="oktaLoading"
      :fp-loader-title="fploader.title"
      :fp-loader-desc="fploader.desc"
    ></full-page-loader>
    <component :is="$route.meta.layout || 'div'" v-else>
      <router-view />
    </component>
  </div>
</template>

<script>
import { Spinner, FullPageLoader } from '@cendyn/cendyn-frontend-kit';
import { mapGetters, mapActions } from 'vuex';
import { LocalStorageMixin } from './mixins';

export default {
  name: 'App',
  components: {
    Spinner,
    FullPageLoader
  },
  mixins: [ LocalStorageMixin ],
  data() {
    return {
      oktaLoading: false,
      fploader: {
        title: 'Loading...',
        desc: ''
      }
    };
  },
  computed: {
    ...mapGetters('currentUser', [
      'getUserCurrentApp',
      'getUserCurrentOrg'
    ])
  },
  async mounted() {
    this.oktaLoading = true;
    await this.$auth
      .verifyUser()
      .then(async authResult => {
        if (!authResult) {
          this.oktaLoading = false;
        } else if (authResult === 'invalid') {
          this.$router.push({ name: 'Signin' });
          this.oktaLoading = false;
        } else {
          authResult = authResult ?? JSON.parse(localStorage.getItem('okta-token-storage') ?? '{}');
          await this.initializeUser( authResult?.token, authResult?.user ?? {});
          this.$nextTick(function () {
            this.oktaLoading = false;
          });
        }
      });
  },
  methods: {
    ...mapActions('currentUser', [
      'setCurrentUserStore'
    ]),
    ...mapActions('cendynRegistry', [
      'fetchApplicationsAsync'
    ]),
    async initializeUser(accessToken, userInfo) {
      await this.fetchApplicationsAsync().then((res)=> {
        const app = res.filter(x => x.name === 'Home');
        const appId = app[0]?.applicationId;
        this.setCurrentUserStore({ TOKEN: accessToken, INFO: userInfo, APPID: appId, ORGID: null });
      });
    }
  }
};
</script>

<style lang="scss">
  @use "../node_modules/@cendyn/cendyn-frontend-kit/src/assets/scss/theme.scss";
  @import "./assets/scss/home.scss";
</style>
